.active_contracts_list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end !important;

    &__icon {
      width: 15px;
      margin-right: 3px;
      font-size: 15px;
    }
  }
}
