ul#containers_list > li {
  h3,
  .cardTitle {
    color: #4b8df9;
    font-weight: 700;
    font-size: 1.8rem;
    flex: 1 50%;
    word-break: break-all;
  }

  p {
    color: grey;
    font-weight: 400;
  }

  img {
    width: 15px;
    margin-right: 10px;
  }
}

