.btn {
  display: inline-flex;
  width: inherit;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $main-color-blue;
  box-shadow: inset 0 0 0 0.2rem $main-color-blue;
  border-radius: 0.4rem;
  padding: 10px 1.6rem;
  font-size: 1.4rem;
  color: $white;
  fill: $white;
  text-align: center;
  text-transform: uppercase;

  &[disabled] {
    opacity: 0.5;
  }

  &__label {
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
    color: $white;
    fill: $white;
    box-shadow: inset 0 0 0 0.2rem $main-color-blue-hover;
  }

  &--border {
    background-color: transparent;
    color: $main-color-blue;
    fill: $main-color-blue;
  }

  &--filter {
    min-height: inherit;
    padding: 1rem 1.6rem;
    background-color: transparent;
    color: $color-gray;
    fill: $color-gray;
    text-transform: inherit;
    box-shadow: inset 0 0 0 0.1rem $color-gray;

    &:hover {
      box-shadow: inset 0 0 0 0.2rem darken($color-gray-dark, 0.2);
      color: darken($color-gray-dark, 0.2);
      fill: darken($color-gray-dark, 0.2);
      background-color: inherit;
    }

    &-active {
      color: $color-gray-darker;
      fill: $color-gray-darker;
      box-shadow: inset 0 0 0 0.2rem $color-gray-darker;

      &:hover {
        box-shadow: inset 0 0 0 0.2rem darken($color-gray-darker, 0.2);
        color: darken($color-gray-darker, 0.2);
      }
    }

    &.btn--with-icon {
      padding: 0.8rem 1.6rem;
    }
  }

  &--secondary {
    background-color: $sec-color-lightblue;
    box-shadow: inset 0 0 0 0.2rem $sec-color-lightblue;
    text-transform: none;
    color: $main-color-blue;
    fill: $main-color-blue;
  }

  &--small {
    min-height: 3.2rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  &--with-icon {
    justify-content: flex-start;
  }

  &--with-left-icon {
    .btn__left-icon {
      font-size: 2.4rem;
      margin-right: 0.8rem;
      margin-left: -0.8rem;
    }
  }

  &--with-right-icon {
    .btn__label {
      margin-right: 0.8rem;
    }

    .btn__right-icon {
      font-size: 2.4rem;
      margin-left: auto;
      flex-shrink: 0;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--half-width {
    width: 50%;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--square {
    padding: 100% 1.6rem 0 1.6rem;
    width: 100%;
    position: relative;

    .btn__label {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }

    .btn__right-icon {
      position: absolute;
      top: 72%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      margin: 0 auto;
    }
  }
}
