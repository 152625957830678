
ul#containers_list {
  width: 80%;
  margin: auto;

  > li {
    margin: 1em 0;
    min-height: 50px;
    padding: 1em;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background: #ffffff;

    &:focus {
      outline: none;
    }

  }
}

