.tags_checkbox_group {
  margin-top: 1em;
  border-color: #f4f7f9;

  legend {
    color: hsl(0, 0%, 50%);
    font-size: 1.4rem;
    font-weight: 500;
  }

  label {
    margin-bottom: .5em;
    display: block;
  }
}
